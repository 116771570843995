.return-page h1 {
	font-size: 4rem; 
	margin-bottom: 2rem;
	margin-top: 2rem;
}

.return-page {
	display: flex;
  flex-direction: column;
  align-items: center;
	text-align: center;
}