.app-content {
  background-color: #333; 
  color: #fff; 
  min-height: 100vh; 
  padding: 20px; 
}

.app-header {
  text-align: center;
  font-size: 3em; 
  margin-top: 1rem; 
  margin-bottom: 1rem; 
}

.app-body {
  display: flex;
  flex-direction: row;
  height: 80vh;
}

.column {
  flex: 1;
}

.report-container {
  border: 1px solid black;
  height: 90%;
  padding: 10px;
  background-color: #f5f5f5; 
  color: black; 
  overflow-y: auto;
}

.input-row {
  margin-bottom: 0.5rem; /* Space between rows */
}

.input-field {
  margin-right: 3rem; /* Space between inputs */
}

.performance-button {
  height: 4.5rem;
  width: 23%; 
  margin-right: 2%;
  margin-bottom: 0.5rem;
}

.comp-topic-area-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.comp-topic-input {
  width: 70% !important;
  flex-grow: 1;
  margin-right: 1rem;
  display: inline-block;
}

.fix-width-btn {
  width: 30%;
}

.trash-button {
  display: inline-block;
  vertical-align: top;
}

.bi-trash {
  font-size: 2rem; 
  color: rgb(255, 135, 155);
}

/* Overlay to freeze the screen and make it slightly lighter */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7); /* Light shade */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  z-index: 1000; /* Ensure it's above other content */
}

/* Spinning dots loading animation */
.spinner {
  border: 1rem solid rgba(0, 0, 0, 0.1);
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  border-left-color: #09f;
  margin-bottom: 2rem;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

button, .bi-trash {
  cursor: pointer;
}

