.login-box {
  max-width: 40%;
  margin: 0 auto;
}

.login-box input,
.login-box button {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
}

hr {
  margin: 20px 0;
}


